'use client';

import * as Sentry from '@sentry/nextjs';
import React, { useEffect } from 'react';
import HttpError from '@/src/shared/api/http-error';
import './global.scss';
const GlobalError: unknown = ({
  error
}: {
  error: Error & HttpError & {
    digest?: string;
  };
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html className="w-full h-full" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className="w-full h-full">
        <div className="w-full min-h-full flex items-center justify-center col-span-full p-4 sm:p-6">
          <div className="flex flex-col items-center text-center ">
            <div className="relative aspect-[582/978] w-[130px] mb-4">
              <img src="/assets/images/anime-girl-guilty.png" alt="Something went wrong" className="object-cover" />
              <div className="absolute inset-0 z-10 bg-gradient-to-t from-bgBase-500 to-40%" />
            </div>
            <h1 className="text-3xl sm:text-4xl font-bold text-primary-500 mb-4 max-w-xl">
              Oops! Something went wrong
            </h1>
            <p className="text-sm sm:text-base mb-6 max-w-lg">
              We are currently experiencing technical difficulties. We are
              already working on it and
            </p>
            {error && <p className="text-xs sm:text-base mt-6 max-w-lg opacity-30">
                {error?.message ?? error?.digest}
              </p>}
          </div>
        </div>
      </body>
    </html>;
};
export default GlobalError;